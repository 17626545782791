'use strict';

import { Cookie } from './cookies';

const xClientSessionKey = 'x-client-session';
const xClientSessionDeviceKey = 'x-client-session-device';
import { v1 as uuidv1 } from 'uuid';

export function getClientSessionValue() {
  let sessionCookie = Cookie.get(xClientSessionKey);

  if (!sessionCookie) {
    sessionCookie = uuidv1();
  }
  //Renew Cookie for 30 minutes
  Cookie.set(xClientSessionKey, sessionCookie, 0, 30);

  return sessionCookie;
}

export function getClientSessionDeviceValue() {
  let sessionCookie = Cookie.get(xClientSessionDeviceKey);
  if (!sessionCookie) {
    sessionCookie = uuidv1();
  }

  Cookie.set(xClientSessionDeviceKey, sessionCookie);

  return sessionCookie;
}
