import { isBot } from '@lib/isBot';
import {
  Cookie,
  COOKIE_PUBLIC_ID,
  COOKIE_X_CLIENT_SESSION_DEVICE
} from '@lib/cookies';

window.mParticle = window.mParticle || { ready: false };
window.braze = {};
window.dynamicYield = {};

const planId = 'petlove';
const planVersion = 1;
const session_device = Cookie.get(COOKIE_X_CLIENT_SESSION_DEVICE);
const public_id = Cookie.get(COOKIE_PUBLIC_ID);
const API_KEY = window.webstore?.isWhitelabel
  ? process.env.MPARTICLE_WHITELABEL_API_KEY
  : process.env.MPARTICLE_API_KEY;

export default () => {
  const isRobotAccess = isBot(window.navigator.userAgent);
  if (process.env.MPARTICLE_ENABLED === 'false' || isRobotAccess) {
    return;
  }

  const webSdk = () => {
    return import(
      /* webpackChunkName: "mparticle-web-sdk" */
      '@mparticle/web-sdk'
    );
  };

  const braze = () => {
    return import(
      /* webpackChunkName: "mparticle-web-braze-kit" */
      '@mparticle/web-braze-kit'
    );
  };

  const dynamicYield = () => {
    return import(
      /* webpackChunkName: "mparticle-web-dynamic-yield-kit" */
      '@mparticle/web-dynamic-yield-kit'
    );
  };

  return webSdk()
    .then((mparticle) => {
      window.mParticle = mparticle.default;
      return braze();
    })
    .then((braze) => {
      window.braze = braze.default;
      return dynamicYield();
    })
    .then((dynamicYield) => {
      window.dynamicYield = dynamicYield.default;
      return initMparticle();
    });

  function initMparticle() {
    const mParticleConfig = {
      isDevelopmentMode: process.env.MPARTICLE_DEVELOPMENT_MODE === 'true',
      dataPlan: {
        planId,
        planVersion
      },
      identifyRequest: {
        userIdentities: {
          customerid: public_id
        }
      },
      identityCallback: function (result) {
        if (!result.body.is_logged_in) {
          result.getUser().setUserAttribute('customerid', session_device);
        }
      }
    };

    window.braze?.register(mParticleConfig);
    window.dynamicYield?.register(mParticleConfig);
    window.mParticle?.init(API_KEY, mParticleConfig);
    window.mParticle?.eCommerce?.setCurrencyCode('BRL');
  }
};
