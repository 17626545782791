'use strict';

/*
 * Cookie Functions
 */
export const COOKIE_CART = 'PL_cart';
export const COOKIE_AB_NOT_FOUND = 'PL_ab_not_found';
export const COOKIE_AUTOCOMPLETE_ZIPCODE = 'PL_isAutocompleteZipcode';
export const COOKIE_SCHEDULED_DATE = 'PL_scheduledDate';
export const COOKIE_SHOPS_LOGIN = 'PL_shops_login';
export const COOKIE_PENDING_COUPON = 'PL_pendingCoupon';
export const COOKIE_UTM_PROMOTION = 'IPS_checkout';
export const COOKIE_AB_MARKETPLACE_QUOTATION = 'PL_marketplace_quotation';
export const COOKIE_AB_MARKETPLACE_QUOTATION_DESKTOP =
  'PL_marketplace_quotation_desktop';
export const COOKIE_ZIPCODE = 'PL_zipcode';
export const COOKIE_NEW_CLIENT = 'PL_newClient';
export const COOKIE_AB_SCHEDULED = 'PL_scheduledOptionAb';
export const COOKIE_ORDER_NUMBER = 'PL_orderNumber';
export const COOKIE_ORDER_NUMBER_MOCK = 'PL_orderNumber_mock';
export const AB_PROGRESS_BAR = 'PL_ab_progress_bar';
export const COOKIE_ORDER_TOKEN = 'PL_orderTokenId';
export const COOKIE_EMAIL = 'PL_email';
export const COOKIE_SCOOBY = 'PL_scooby_token';
export const FINGERPRINT = 'PL_fingerprint';
export const COOKIE_PUBLIC_ID = 'PL_publicId';
export const COOKIE_SUBSCRIBER = 'PL_isSubscriber';
export const COOKIE_DISCOUNTCLUB_MEMBER = 'PL_isDiscountClubMember';
export const COOKIE_ELIGIBLE_SUBSCRIBER = 'PL_isEligibleSubscriber';
export const COOKIE_X_CLIENT_SESSION = 'x-client-session';
export const COOKIE_X_CLIENT_SESSION_DEVICE = 'x-client-session-device';
export const COOKIE_PRODUCT_PROMOTIONAL_BANNER = 'PL_productPromotionalBanner';
export const COOKIE_ONBOARD_PROFILE_UPDATED = 'PL_petProfileUpdated';
export const COOKIE_DONATION_TOKEN = 'PL_donationTokenId';
export const COOKIE_DONATION_NUMBER = 'PL_donationNumber';
export const COOKIE_DONATION_SLUG = 'PL_donationSlug';
export const COOKIE_DONATION_NAME = 'PL_donationName';
export const COOKIE_SUBSCRIPTION_FEEDBACK = 'PL_subscriptionFeedback';
export const COOKIE_SUBSCRIPTION_SOURCE = 'PL_subscription_source';
export const COOKIE_SNACKBAR = 'PL_snackbarMessage';
export const COOKIE_SHIPPING_METHOD = 'PL_shipping_method';
export const COOKIE_PICKUP_CHOOSED = 'PL_pickup_choosed';
export const COOKIE_AB_TEST = 'PL_ab_test';
export const COOKIE_USER_CLICK_NO_SHOW_DOWNLOAD_APP =
  'PL_user_click_no_show_download_app';
export const COOKIE_GOOGLE_ONE_TAP_STATE = 'g_state';
export const COOKIE_PRIVACITY = 'PL_cookie_privacity';
export const COOKIE_REPET_REPLACE_PRODUCT_INIT =
  'PL_repet_replace_product_init';
export const COOKIE_REPET_REPLACE_PRODUCT_CONSUMABLE =
  'PL_repet_replace_product_consumable';
export const COOKIE_REPET_REPLACE_PRODUCT_DELIVERY_ID =
  'PL_repet_replace_product_delivery_id';
export const COOKIE_PET_ID = 'PL_pet_id';
export const COOKIE_PET_SPECIES = 'PL_pet_species';
export const COOKIE_REPET_SELECTED_REASON_TO_CANCEL =
  'PL_repet_selected_reason_to_cancel';
export const COOKIE_SUBSCRIPTION_DISCOUNT_PERCENTAGE =
  'PL_subscriptionPercentageDiscountABTest';
export const PL_PRIME_AVAILABLE = 'PL_primeAvailable';
export const COOKIE_SUBSCRIPTION_PRODUCTS = 'PL_subscription_products';
export const COOKIE_ADVANTAGE_NETWORK = 'ADVANTAGE_NETWORK';
export const COOKIE_ADVANTAGE_NETWORK_MEDIUM = 'ADVANTAGE_NETWORK_MEDIUM';
export const COOKIE_DY_ID = '_dyid';
export const COOKIE_FREE_SHIPPING = 'PL_free_shipping';
export const COOKIE_FREE_SHIPPING_THRESHOLD = 'PL_free_shipping_threshold';
export const COOKIE_PL_PRIME_COLLECTION_VIEWED = 'PL_primeCollectionViewed';
export const COOKIE_VERIFICATION_PIN_ID = 'PL_verification_pin_id';
export const COOKIE_AB_TEST_MODAL_PRODUCT_CARDS_CATALOG =
  'PL_test_ab_modal_product_cards_catalog';
export const CART_PERIOD_UNIT = 'PL_cart_period_unit';
export const COOKIE_USER_PURCHASE_PATH = 'PL_user_purchase_path';
export const COOKIE_TOUR_MODAL = 'PL_repet_tour_modal';
export const COOKIE_BLOCK_SPOT_BUY = 'PL_block_spot_buy';
export const COOKIE_REGULARITY_RECOMMENDATION =
  'PL_repet_disable_regularity_recommendation';
export const COOKIE_SSO_LOGGED = 'PL_sso_logged';
export const COOKIE_AB_ECONOMY_CART = 'PL_showAbEconomy';
export const COOKIE_NUPAY_AUTHORIZATION = 'PL_nupay_authorization_status';
export const COOKIE_NUPAY_RETRIES = 'PL_nupay_authorization_retries_quantity';

export const Cookie = {
  get(cname) {
    let name = cname + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) == ' ') c = c.substring(1);
      if (c.indexOf(name) != -1) {
        let cookie = c.substring(name.length, c.length);
        cookie = decodeURIComponent(cookie);

        return cookie;
      }
    }
    return null;
  },
  set(cname, _cvalue, exdays, minutes) {
    let cvalue = JSON.stringify(_cvalue);
    cvalue = JSON.parse(cvalue);

    if (!exdays) exdays = 90;

    let d = new Date();
    let time = exdays * 24 * 60 * 60 * 1000;

    if (minutes) {
      time = minutes * 60 * 1000;
    }

    d.setTime(d.getTime() + time);

    let expires = 'expires=' + d.toUTCString() + 'max-age=' + d.toUTCString();
    cvalue = encodeURIComponent(cvalue);

    document.cookie = cname + '=' + cvalue + ';path=/;' + expires;
  },
  delete(cname) {
    this.set(cname, '', -10);
  }
};

export function getAllCookiesByName(cookieName) {
  try {
    return document.cookie
      .split(';')
      .filter(function (c) {
        return c.trim().indexOf(cookieName) === 0;
      })
      .map(function (c) {
        return c.trim();
      });
  } catch (e) {
    return [];
  }
}

export const Cart = {
  get(key) {
    let cartCookie = Cookie.get(COOKIE_CART);

    if (cartCookie) {
      cartCookie = JSON.parse(cartCookie);
    } else {
      cartCookie = {
        totalItems: '0',
        amount: '0'
      };
    }

    return key ? cartCookie[key] : cartCookie;
  },
  getTotalItems() {
    return this.get('totalItems');
  },
  getTotal() {
    return this.get('amount') || 0;
  },
  set(cart) {
    if (cart) {
      cart = JSON.stringify(cart);
      Cookie.set(COOKIE_CART, cart);
    }
  },
  delete() {
    Cookie.delete(COOKIE_CART);
  }
};

export const Promotion = {
  getUtmCookies() {
    let utmCookie = Cookie.get(COOKIE_UTM_PROMOTION);
    return utmCookie ? JSON.parse(utmCookie) : null;
  },
  getPendingCoupon() {
    let pendingCoupon = Cookie.get(COOKIE_PENDING_COUPON);

    return pendingCoupon ? pendingCoupon : false;
  },
  deletePendingCoupon() {
    Cookie.delete(COOKIE_PENDING_COUPON);
  }
};

export const Order = {
  number() {
    return Cookie.get(COOKIE_ORDER_NUMBER);
  },
  email() {
    return Cookie.get(COOKIE_EMAIL);
  },
  token() {
    return Cookie.get(COOKIE_ORDER_TOKEN);
  }
};

export const Pet = {
  setId(id = '') {
    Cookie.set(COOKIE_PET_ID, id);
  },
  setSpecies(pets = []) {
    const species = pets.length
      ? pets
          .reduce((acc, pet) => {
            if (acc.includes(pet.specie)) return acc;

            return acc + pet.specie + ',';
          }, '')
          .replace(/,\s*$/, '')
      : '';

    Cookie.set(COOKIE_PET_SPECIES, species);
  }
};
