'use strict';

import { notifyHoneyBadgerError } from '@lib/honeyBadger.js';

export function verifyWebstoreContext(webstoreData = '') {
  const [subdomain] = location.hostname.split('.petlove.com.br');
  const rgxp = new RegExp(
    'staging|localhost|local|www|no-cache|gh-|greyhound-'
  );

  if (rgxp.test(subdomain)) {
    return;
  }

  if (subdomain !== window?.webstore?.subdomain) {
    const context = {
      webstoreData,
      locationHostname: subdomain,
      hostname: location.hostname,
      webstoreWindow: window.webstore
    };

    notifyHoneyBadgerError('Subdomain Webstore Context Error - Client Side', {
      context
    });
  }
}

export function verifyWebstoreContextData() {
  let webstoreData = document.getElementById('webstore-subdomain-data');
  if (!webstoreData) return;

  webstoreData = webstoreData.innerHTML;
  verifyWebstoreContext(webstoreData);
}
