'use strict';

import pl_events from '@lib/pubSub';
import { loginUserEvent } from '../lib/tagmanager';
import { Cookie, COOKIE_PUBLIC_ID } from '@lib/cookies';
import { requestPost } from '@lib/fetch';
import { mParticleLogin } from '@lib/mparticle';

export const GOOGLE_SIGNIN = 'https://accounts.google.com';
export const GOOGLE_ID =
  '447418113095-p03nqdsrukba19jan6hfuc69qd170ne8.apps.googleusercontent.com';

export const insertScriptGoogle = () => {
  if (document.getElementById('google-js')) {
    return;
  }

  if (window?.webstore?.isWhitelabel) {
    _insertScriptGoogleByOtter();
    return;
  }
};

export const googleSuccessLogin = (result) => {
  loginUserEvent('google', 'success');

  const info = result.json ? result.json() : result;
  const { email, public_id } = info;

  Cookie.set('PL_email', email);
  Cookie.set(COOKIE_PUBLIC_ID, public_id);
};

export const googleErrorLogin = () => {
  loginUserEvent('google', 'error');
};

export const googleOauthByOtter = () => {
  pl_events.publish(pl_events.events.social_login, true);
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    if (oAuthLogin) {
      const [, protocol, host] = window.webstore.LOGIN_HOST.match(
        /(https?):\/\/([\w\-\.\:]+)/
      );

      // eslint-disable-next-line no-undef
      oAuthLogin('beagle_google', {
        protocol,
        host,
        height: 599,
        width: 599,
        audience: 'chameleon',
        build: '1.0.0',
        origin: 'petlove.com.br'
      })
        .then((googleUser) => {
          // eslint-disable-next-line no-undef
          const { data } = decodeToken(googleUser.token);
          return data;
        })
        .then((user) => {
          requestPost('/session/create', { ...user });

          mParticleLogin(user);
          loginUserEvent('google', 'success');
          Cookie.set('PL_email', user.email);
          window.location.href = window.callbackLogin || '/';
          resolve(user);
        })
        .catch((reason) => {
          pl_events.publish(pl_events.events.social_login, false);
          loginUserEvent('google', 'error');
          reject(reason);
        });
    }
  });
};

function _insertScriptGoogleByOtter() {
  let po = document.createElement('script');
  po.type = 'text/javascript';
  po.async = true;
  po.id = 'google-js';
  po.onload = function () {}.bind(this);
  po.src = window.webstore.LOGIN_HOST;
  let s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(po, s);
}
