import {
  getOnboardingPetSteps,
  getSubscriptionSteps,
  getCheckoutSteps
} from './mParticleSteps';
import { setSubscriptionProductMParticle } from './productFormatter';
import {
  Cookie,
  COOKIE_PUBLIC_ID,
  COOKIE_EMAIL,
  COOKIE_X_CLIENT_SESSION_DEVICE
} from '@lib/cookies';
import { notifyHoneyBadgerError } from '@lib/honeyBadger';

let catalogQueryId = window.localStorage?.getItem('algoliaSearchQueryId') || '';
const webstore = window.webstore?.webstore_label;
const eventSourceURL = window.location.href;

export const createProducts = ({
  name,
  sku,
  price,
  quantity,
  variant,
  category,
  brand,
  coupon,
  position,
  customAttributes
}) => {
  return window.mParticle?.eCommerce?.createProduct(
    name,
    sku && sku.split(':')[0], // Prevent sending combo sku
    price,
    quantity,
    variant,
    category,
    brand,
    position,
    coupon,
    customAttributes
  );
};

const getProductObject = (product, hasCustomAttributes) => {
  const customAttributes = hasCustomAttributes
    ? {
        customAttributes: {
          subscriber_price: product.metric4
        }
      }
    : {};

  return {
    name: product.name,
    sku: product.sku,
    price: product.price,
    quantity: product.quantity || 1,
    variant: product.variant,
    category: product.category,
    brand: product.brand,
    position: product.position || undefined,
    coupon: undefined,
    customAttributes
  };
};

export const mParticleViewDetail = (products = []) => {
  if (!products.length || !window.mParticle?.ready) return;

  const productsPayload = products.map((product) => {
    return createProducts(getProductObject(product, true));
  });

  const customAttributes = {
    query_id: catalogQueryId,
    webstore,
    ..._setIdCustomAttributes()
  };

  const customFlags = { 'Facebook.EventSourceUrl': eventSourceURL };

  window.mParticle?.eCommerce?.logProductAction(
    window.mParticle?.ProductActionType?.ViewDetail,
    productsPayload,
    customAttributes,
    customFlags
  );
};

export const mParticleAddToCart = (products = []) => {
  if (!products.length || !window.mParticle?.ready) return;

  const productsPayload = products.map((product) => {
    return createProducts(getProductObject(product, true));
  });

  const productsFiltered = _removeProductsWithoutPrice(productsPayload);
  const customAttributes = {
    query_id: catalogQueryId,
    webstore,
    ..._setIdCustomAttributes()
  };
  const customFlags = { 'Facebook.EventSourceUrl': eventSourceURL };

  if (productsFiltered.length)
    window.mParticle?.eCommerce?.logProductAction(
      window.mParticle?.ProductActionType?.AddToCart,
      productsFiltered,
      customAttributes,
      customFlags
    );
};

export const mParticleRemoveFromCart = (products = []) => {
  if (!products.length || !window.mParticle?.ready) return;

  const productsPayload = products.map((product) => {
    return createProducts(getProductObject(product, true));
  });

  const productsFiltered = _removeProductsWithoutPrice(productsPayload);
  const customAttributes = {
    query_id: catalogQueryId,
    webstore,
    ..._setIdCustomAttributes()
  };
  const customFlags = { 'Facebook.EventSourceUrl': eventSourceURL };

  if (productsFiltered.length)
    window.mParticle?.eCommerce?.logProductAction(
      window.mParticle?.ProductActionType?.RemoveFromCart,
      productsFiltered,
      customAttributes,
      customFlags
    );
};

export const mParticlePurchase = (products = [], order, isFirstBuy) => {
  if (!products.length || !window.mParticle?.ready) return;

  const productsPayload = products
    .filter((product) => product.price)
    .map((product) => createProducts(getProductObject(product, false)));

  const {
    discount_total,
    number,
    total,
    tax_total,
    ship_total,
    item_total,
    promotion_codes,
    subscription,
    discount_club_order_subscribed
  } = order;

  const transactionAttributes = {
    Id: number,
    CouponCode: promotion_codes?.[0],
    Revenue: total,
    Shipping: ship_total,
    Tax: tax_total
  };

  const customAttributes = {
    subtotal_amount: item_total,
    discount_amount: discount_total,
    first_buy: isFirstBuy,
    query_id: catalogQueryId,
    webstore,
    subscribed: Boolean(subscription.subscribed) || false,
    gifts: products
      .filter((product) => !product.price)
      .reduce((acc, product) => acc + product.id + ',', '')
      .replace(/,\s*$/, ''),
    ..._setIdCustomAttributes(),
    discount_club_order_subscribed: discount_club_order_subscribed
  };

  const productsFiltered = _removeProductsWithoutPrice(productsPayload);
  const customFlags = { 'Facebook.EventSourceUrl': eventSourceURL };

  if (productsFiltered.length)
    window.mParticle?.eCommerce?.logProductAction(
      window.mParticle?.ProductActionType?.Purchase,
      productsFiltered,
      customAttributes,
      customFlags,
      transactionAttributes
    );
};

export const mParticleAddToWishList = (products = []) => {
  if (!products.length || !window.mParticle?.ready) return;

  const productsPayload = products.map((product) => {
    return createProducts(getProductObject(product, true));
  });

  const customAttributes = { webstore, ..._setIdCustomAttributes() };
  const customFlags = { 'Facebook.EventSourceUrl': eventSourceURL };

  window.mParticle?.eCommerce?.logProductAction(
    window.mParticle?.ProductActionType?.AddToWishlist,
    productsPayload,
    customAttributes,
    customFlags
  );
};

export const mParticleCheckoutOption = (name = '', order = {}) => {
  const { number, total, tax_total, promotion_codes, ship_total, line_items } =
    order;

  if (!line_items?.length || !window.mParticle?.ready) return;

  const productsPayload = line_items.map((product) => {
    return createProducts({
      name: product.name,
      sku: product?.sku,
      price: product.price,
      quantity: product.quantity,
      variant: product?.short_name,
      category: product.category,
      brand: product.brand,
      position: undefined,
      coupon: undefined
    });
  });

  const step = getCheckoutSteps(name);

  const transactionAttributes = {
    Id: number,
    Revenue: total,
    Tax: tax_total,
    CouponCode: promotion_codes[0],
    Shipping: ship_total,
    Step: step.value,
    Option: step.name
  };

  const customAttributes = { webstore, ..._setIdCustomAttributes() };
  const customFlags = { 'Facebook.EventSourceUrl': eventSourceURL };

  window.mParticle?.eCommerce?.logProductAction(
    window.mParticle?.ProductActionType?.CheckoutOption,
    productsPayload,
    customAttributes,
    customFlags,
    transactionAttributes
  );
};

export const mParticleLogin = (user, callbackHandler) => {
  if (!window.mParticle?.ready) {
    if (callbackHandler) callbackHandler();
    return Promise.resolve(user);
  }

  const { email, public_id, publicId } = user;
  const customerid = publicId || public_id || null;

  let identityRequest = {
    userIdentities: {
      customerid
    }
  };

  if (email) {
    _submitLoginOrSignupEvent(user);

    const isAppboyInitialized = window.appboy?.initialize();
    if (isAppboyInitialized) {
      window.appboy?.changeUser(customerid);
    }
  }
  try {
    window.mParticle?.Identity?.login(identityRequest, () => {
      if (callbackHandler) {
        callbackHandler();
      }
    });
  } catch (error) {
    notifyHoneyBadgerError('mParticle login v2 error', {
      error
    });
  }

  return Promise.resolve(user);
};

export const mParticleLogout = () => {
  if (!window.mParticle?.ready) return;

  const email = Cookie.get(COOKIE_EMAIL) || '';

  if (email) {
    mParticleCustomEvent('logout', 'Social', {
      email,
      webstore,
      ..._setIdCustomAttributes()
    });
  }

  window.mParticle?.Identity?.logout({});
};

export const mParticleOnboardingPet = (name, value) => {
  if (!name || !window.mParticle?.ready) return;

  const step = getOnboardingPetSteps(name);

  const eventPayload = {
    onboarding_step: step.value,
    onboarding_option: step.name,
    onboarding_value: value,
    webstore,
    ..._setIdCustomAttributes()
  };

  mParticleCustomEvent('onboarding_pet', 'Other', eventPayload);
};

export const mParticleSubscription = (eventName, item = {}, stepName = '') => {
  if (!eventName || !window.mParticle?.ready) return;

  const step = getSubscriptionSteps(stepName);
  const payload =
    item.sku || item.id ? setSubscriptionProductMParticle(item) : { ...item };

  const subscriptionStepData = step?.name
    ? {
        subscription_step: step.value,
        subscription_option: step.name
      }
    : {};

  const eventPayload = {
    ...subscriptionStepData,
    ...payload,
    webstore,
    ..._setIdCustomAttributes()
  };

  mParticleCustomEvent(eventName, 'Other', eventPayload);
};

export const mParticleCustomEvent = (name, type, customAttrs = {}) => {
  if (!type || !window.mParticle?.ready) return;
  return window.mParticle?.logEvent(name, window.mParticle?.EventType[type], {
    ...customAttrs,
    ..._setIdCustomAttributes()
  });
};

function _submitLoginOrSignupEvent(user) {
  const { email, public_id, publicId, new_record, source } = user;
  const customerid = publicId || public_id;

  const event = new_record ? 'signup' : 'login';

  mParticleCustomEvent(event, 'Social', {
    email,
    customerid,
    facebook: source == 'facebook' ? email : '',
    google: source == 'google_api' ? email : '',
    webstore
  });
}

function _removeProductsWithoutPrice(products) {
  return products.filter((product) => {
    return product.Price;
  });
}

export const mParticleClick = (
  products = [],
  origin = '',
  order = {},
  catalogQueryId = ''
) => {
  if (!products.length || !window.mParticle?.ready) return;

  const productsPayload = products.map((product) => {
    return createProducts(getProductObject(product, true));
  });

  const customAttributes = {
    query_id: catalogQueryId,
    webstore,
    origin,
    ..._setIdCustomAttributes()
  };

  const transactionAttributes = {
    Id: order?.number,
    Revenue: order?.total
  };

  window.mParticle?.eCommerce?.logProductAction(
    window.mParticle?.ProductActionType?.Click,
    productsPayload,
    customAttributes,
    {},
    transactionAttributes
  );
};

function _setIdCustomAttributes() {
  return {
    customerid: Cookie.get(COOKIE_PUBLIC_ID) || '',
    sessionid: Cookie.get(COOKIE_X_CLIENT_SESSION_DEVICE) || ''
  };
}
