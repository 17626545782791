window.DY = window.DY || {};

export default () => {
  if (process.env.MPARTICLE_ENABLED === 'false') return;

  try {
    const { type, data } = _getPageInfo();

    window.DY.recommendationContext = {
      type,
      ...(data.length && { data })
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

function _getPageInfo() {
  const url = window.location.pathname;

  let type = '';
  let data = [];

  switch (true) {
    case url == '/':
      type = 'HOMEPAGE';
      break;
    case _isCart(url):
      type = 'CART';
      break;
    default:
      type = 'OTHER';
  }

  return { type, data };
}

function _isCart(url) {
  return url === '/carrinho';
}
