import LazyLoad from 'vanilla-lazyload';

export const initLazyLoad = async function () {
  //Load images lazyload
  window.lazyLoad = new LazyLoad({
    use_native: true,
    elements_selector: '.lazyload',
    class_loaded: 'lazyloaded'
  });
  //Update for lazyload images from async components loaded
  const observer = new MutationObserver(() => {
    window.lazyLoad.update();
  });
  observer.observe(document.body, {
    attributes: true,
    childList: true,
    subtree: true
  });
  window.lazyLoad.update();
};
