'use strict';

import {
  getClientSessionValue,
  getClientSessionDeviceValue
} from './clientSession';

import { getRunningAbTests } from '@lib/runningAbTests';
import { COOKIE_AB_TEST } from '@lib/cookies';

export function getHeaders(headers = {}, isFormData = false) {
  const abTestHeaders = getRunningAbTests(COOKIE_AB_TEST);

  let finalHeaders = {
    Accept: 'application/json',
    'Original-User-Agent': navigator?.userAgent,
    ...headers
  };

  if (finalHeaders.removeCommonHeader) {
    delete finalHeaders.removeCommonHeader;
  } else {
    finalHeaders = {
      'X-Client-Session': getClientSessionValue(),
      'X-Client-Session-Device': getClientSessionDeviceValue(),
      ...abTestHeaders,
      ...finalHeaders
    };
  }

  window?.webstore?.isWhitelabel &&
    (finalHeaders['Whitelabel-Subdomain'] = window?.webstore?.subdomain);

  if (!isFormData) {
    finalHeaders['Content-Type'] = 'application/json';
  }

  return finalHeaders;
}

export async function resolveResponse(response, responseType) {
  let responseData = response;

  try {
    switch (responseType) {
      case 'json':
        responseData = await response.json();
        break;
      case 'text':
        responseData = await response.text();
        break;
    }
  } catch (e) {
    responseData = response;
  }

  if (response.status.toString().charAt(0) !== '2') {
    return Promise.reject(responseData);
  } else {
    return Promise.resolve(responseData);
  }
}

export async function requestPost(
  url,
  data,
  headers = {},
  isFormData = false,
  responseType = 'json'
) {
  let finalHeaders = getHeaders(headers, isFormData);
  let body = isFormData ? data : JSON.stringify(data);

  let response = await fetch(url, {
    credentials: 'same-origin',
    method: 'POST',
    body,
    headers: finalHeaders
  });

  return resolveResponse(response, responseType);
}

export async function requestPut(
  url,
  data,
  headers = {},
  isFormData = false,
  responseType = 'json'
) {
  let finalHeaders = getHeaders(headers, isFormData);
  let body = isFormData ? data : JSON.stringify(data);

  let response = await fetch(url, {
    credentials: 'same-origin',
    method: 'PUT',
    body,
    headers: finalHeaders
  });

  return resolveResponse(response, responseType);
}

export async function requestGet(url, headers = {}, responseType = 'json') {
  let finalHeaders = getHeaders(headers);

  let response = await fetch(url, {
    credentials: 'same-origin',
    method: 'GET',
    headers: finalHeaders
  });

  return resolveResponse(response, responseType);
}

export async function requestDelete(url, headers = {}, responseType = 'json') {
  let finalHeaders = getHeaders(headers);

  let response = await fetch(url, {
    credentials: 'same-origin',
    method: 'DELETE',
    headers: finalHeaders
  });

  return resolveResponse(response, responseType);
}
