'use strict';

import { insertScriptFacebook } from './facebookService';
import { insertScriptGoogle } from './googleService';
import {
  Cookie,
  COOKIE_REPET_REPLACE_PRODUCT_INIT,
  COOKIE_REPET_REPLACE_PRODUCT_CONSUMABLE,
  COOKIE_REPET_REPLACE_PRODUCT_DELIVERY_ID,
  COOKIE_REPET_SELECTED_REASON_TO_CANCEL
} from '@lib/cookies';
import { mParticleLogin, mParticleLogout } from '@lib/mparticle';

export const FACEBOOK_LOGIN = 'https://www.facebook.com';
export const PASSWORD_LOGIN = 'password';
export const DEFAULT_IMG =
  'https://static3.petlove.com.br/assets/logo-petlove-border-b1ab84451529381fbcafd33ffd419245.svg';

import { requestGet, requestPost } from '../lib/fetch';

// init google api
export const googleUser = {};

// init facebook api
window.fbAsyncInit = function () {
  window.FB.init({
    appId: '211265535746847', //'153517181872416'
    xfbml: true,
    version: 'v5.0'
  });
  window.FB.AppEvents.logPageView();
};

export const insertLoginScripts = () => {
  if (window.document.readyState === 'complete') {
    insertScriptFacebook();
    insertScriptGoogle();
  } else {
    window.addEventListener('load', () => {
      insertScriptFacebook();
      insertScriptGoogle();
    });
  }
};

export const loginSocial = (data) => {
  let pathname = '/session/social';
  let postData = {
    email: data.id,
    source: data.provider === FACEBOOK_LOGIN ? 'facebook' : 'google_api',
    token: data.token,
    fingerprint: data.fingerprint
  };
  return requestLogin(pathname, data, postData);
};

export const getGoogleUserInfo = (googleToken) => {
  let pathname = `/session/social/google/${googleToken}`;
  return requestGet(pathname);
};

export const loginEmail = (data) => {
  const pathname = '/session/v2/auth';
  const postData = {
    email: data.id,
    password: data.password,
    auth: data.auth,
    csrfToken: data.csrfToken,
    fingerprint: data.fingerprint
  };
  return requestLogin(pathname, data, postData);
};

export const loginShopsRottweiler = (data) => {
  const pathname = '/session/auth/shops';
  const postData = {
    email: data.email,
    password: data.password,
    auth: data.auth,
    csrfToken: data.csrfToken,
    fingerprint: data.fingerprint,
    vetus_id: data.vetus_id
  };

  return requestLogin(pathname, data, postData);
};

export const validateAccount = (code, sessionId, verificationId, origin) => {
  const pathname = '/session/validate-account';
  return requestPost(pathname, { code, sessionId, verificationId, origin });
};

export const sendCodeValidateAccountDogHero = (verificationId) => {
  const pathname = '/session/send-code-validate';
  return requestPost(pathname, { verificationId });
};

export const requestLogin = (pathname, data, postData) => {
  const success = function (result) {
    return new Promise((resolve) => {
      mParticleLogin(result, function () {
        saveCredentialAtSmartLock();
        saveCredentialAtLocalStorage(data, result);
        return resolve(result);
      });

      resolve(result);
    });
  };

  const error = function (res) {
    console.error('error', res);
    return new Promise((_resolve, reject) => {
      if (res.status === 403) {
        window.open(res.url, '_self');
        return;
      }
      reject(res);
    });
  };

  return requestPost(pathname, postData).then(success).catch(error);
};

export const saveCredentialAtLocalStorage = (data) => {
  if (navigator.credentials) {
    // eslint-disable-next-line no-unused-vars
    const { password, ...rest } = data;
    localStorage.setItem('credential', JSON.stringify(rest));
  }
};

export const removePasswordLocalCredential = () => {
  const data = localStorage.getItem('credential');
  if (!data) return;

  const json = JSON.parse(data);
  if (!json.password) return;

  saveCredentialAtLocalStorage(json);
};

const removeLocalCredential = () => {
  localStorage.removeItem('credential');
};

export const saveCredentialAtSmartLock = () => {
  if (navigator.credentials) {
    let data = localStorage.getItem('credential');
    if (data) {
      let credential = JSON.parse(data);
      if (
        credential.password &&
        window.PasswordCredential &&
        window.PublicKeyCredential
      ) {
        /* eslint-disable*/
        let cred = new PasswordCredential({
          id: credential.id,
          password: credential.password,
          name: credential.name
        });
        navigator.credentials.store(cred);
        removeLocalCredential();
      } else if (
        credential.id &&
        credential.name &&
        credential.iconURL &&
        credential.provider &&
        window.FederatedCredential
      ) {
        let cred = new window.FederatedCredential({
          id: credential.id,
          name: credential.name,
          iconURL: credential.iconURL,
          provider: credential.provider
        });
        navigator.credentials.store(cred);
        removeLocalCredential();
      }
    }
  }
};

const deleteRepetFlowCookies = () => {
  const cookiesToDelete = [
    COOKIE_REPET_REPLACE_PRODUCT_INIT,
    COOKIE_REPET_REPLACE_PRODUCT_CONSUMABLE,
    COOKIE_REPET_REPLACE_PRODUCT_DELIVERY_ID,
    COOKIE_REPET_SELECTED_REASON_TO_CANCEL,
  ]

  cookiesToDelete.forEach(cookie => {
    Cookie.delete(cookie)
  });
}

const cleanLoginInfo = () => {
  deleteRepetFlowCookies();
  Cookie.delete('PL_sid');
  window.location.assign('/session/logout');
};

export const logout = () => {
  mParticleLogout();
  return cleanLoginInfo();
};

export const recoveryPassword = (email, source) => {
  const pathname = '/session/recovery';
  const postData = source ? { email, source } : { email };

  return requestPost(pathname, postData);
};
