import {
  addToCartEvent,
  addSubscriptionToCartEvent,
  outOfStockEvent,
  customEventV2
} from '@lib/tagmanager';
import pl_events from '@lib/pubSub';
import { mParticleSubscription } from '@lib/mparticle';
import {
  Cookie,
  COOKIE_ELIGIBLE_SUBSCRIBER,
  COOKIE_REPET_REPLACE_PRODUCT_INIT,
  COOKIE_REPET_REPLACE_PRODUCT_CONSUMABLE,
  COOKIE_REPET_REPLACE_PRODUCT_DELIVERY_ID,
  Cart,
  COOKIE_CART,
  COOKIE_ORDER_NUMBER,
  COOKIE_ORDER_TOKEN
} from '@lib/cookies';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { getPetloveUrl, getDevice } from '@lib/global';
import { formatDate } from '@lib/date';
import { addVariantToCart } from '@services/cartService';
import { requestPost } from '@lib/fetch';
import LOCAL_STORAGE_CART_SERVICE from '@services/localStorageCartService';
import { replaceProduct, onRequestError } from '@services/repetService.js';

export const ReminderArea = 'reminder-area';
export const ReminderButton = 'btn-reminder';
export const ReminderWaitInfo = 'reminder-wait-info';
export const HiddenClass = 'hidden';
export let startedRepetReplaceProduct = false;
export let repetReplaceConsumable = null;
export let productPageToCart = false;

let deliveryId = null;
let catalogQueryId = window.localStorage.getItem('algoliaSearchQueryId');
let cartCookie = JSON.parse(Cookie.get(COOKIE_CART)) || {};
const testLoginAfterCartVariant =
  Cookie.get('PL_test_login_after_cart') && !window.isMobileAccess;
let redirectToCartWithoutLogin = false;

if (Cookie.get(COOKIE_REPET_REPLACE_PRODUCT_INIT)) {
  startedRepetReplaceProduct = true;
}

if (Cookie.get(COOKIE_REPET_REPLACE_PRODUCT_DELIVERY_ID)) {
  deliveryId = Cookie.get(COOKIE_REPET_REPLACE_PRODUCT_DELIVERY_ID);
}

if (Cookie.get(COOKIE_REPET_REPLACE_PRODUCT_CONSUMABLE)) {
  repetReplaceConsumable = JSON.parse(
    Cookie.get(COOKIE_REPET_REPLACE_PRODUCT_CONSUMABLE)
  );
}

export const getProductJSON = () => {
  return window.productJSON || { variants: [] };
};

export const getProductPropertyName = (product, prop) => {
  return product[prop] ? product[prop].name : null;
};

export const getVariantJSON = () => {
  return window.variantJSON || { best_installment: { count: 1 } };
};

export const isEligibleSubscriber = () => {
  return JSON.parse(Cookie.get(COOKIE_ELIGIBLE_SUBSCRIBER) || false);
};

export const isLoggedUser = (user = {}) => {
  return user.logged;
};

export const submitReminderOutOfStockEvent = (variant = {}) => {
  if (variant.available || variant.disponivel === 1) return;
  outOfStockEvent(variant.sku);
};

export const loadReminderComponent = (variant) => {
  window.selectedVariant = variant;
  const elementReminderArea = document.getElementById(ReminderArea);
  const elementReminderWaitInfo = document.getElementById(ReminderWaitInfo);

  if (!variant.in_stock) {
    //load reminder component for out of stock variants
    import(
      /* webpackChunkName: "reminder-component" */
      `../components/reminder.js`
    ).then(() => {
      let reminderElements = [ReminderArea, ReminderButton];

      reminderElements.map((id) => {
        const elementId = document.getElementById(id);
        if (elementId) {
          elementId.classList.remove(HiddenClass);
        }
      });

      if (elementReminderWaitInfo) {
        elementReminderWaitInfo.classList.add(HiddenClass);
      }
    });
  } else {
    if (elementReminderArea) {
      elementReminderArea.classList.add(HiddenClass);
    }
  }
};

export const onClickAddToCart = (
  selectedVariant,
  selectedPromotion,
  user,
  event
) => {
  customEventV2({
    event_category: 'product_page_btn',
    event_action: 'clickAddToCart'
  });
  event.preventDefault();
  addToCartEvent(
    selectedVariant,
    'addToCart',
    'Product | Add To Cart Button',
    catalogQueryId
  );

  addToCart(selectedVariant, selectedPromotion, user);
};

export const addToCart = async (
  selectedVariant,
  selectedPromotion,
  user,
  subscribeOrder = {},
  variant = false,
  catalogProductModalTest
) => {
  if (selectedPromotion?.combo_sku) {
    if (!variant) {
      variant = {
        sku: selectedPromotion.combo_sku,
        quantity: 1
      };
    }
    const res = await addVariantToCart([variant], subscribeOrder);
    const { combo_sku } = selectedPromotion;
    const { slug } = selectedVariant;
    const productInfo = { sku: combo_sku, slug, subscribeOrder };
    addToCartSuccess(res, user, productInfo);
  } else {
    if (window.DONATION_CART_KEY) {
      LOCAL_STORAGE_CART_SERVICE.add(selectedVariant, selectedVariant.quantity);
      addToCartSuccess();
    } else {
      if (!variant) {
        variant = {
          sku: selectedVariant.sku,
          quantity: 1
        };
      }
      const res = await addVariantToCart([variant], subscribeOrder);
      const { slug, sku } = selectedVariant;
      const productInfo = { sku, slug, subscribeOrder };
      addToCartSuccess(res, user, productInfo, catalogProductModalTest);
    }
  }
};

export const redirectToLoginWithCartCallback = () => {
  const donationUrl = '/entrar/2f646f61636f65732f63617272696e686f';
  const cartUrl = '/entrar/2f63617272696e686f';
  const cartCallbackUrl = window.DONATION_CART_KEY ? donationUrl : cartUrl;
  window.location.href = cartCallbackUrl;
};

export const redirectToLoginWithProductCallback = (productSlug) => {
  const cartCallbackUrl = `/entrar?callback=/${productSlug}/p?open_modal=true`;
  window.location.href = cartCallbackUrl;
};

export const addToCartSuccess = (
  res,
  user,
  productInfo = {},
  catalogProductModalTest
) => {
  const { slug, sku, subscribeOrder } = productInfo;

  if (res?.order) {
    setCart(res?.order);
  }
  pl_events.publish(pl_events.events.cart_order_ready);

  if (redirectToCartWithoutLogin && testLoginAfterCartVariant) {
    window.location.href = '/carrinho';
    return;
  }

  // Remove After a/b Test

  if (!catalogProductModalTest) {
    if (isIntermediatePageEligible(subscribeOrder)) {
      return redirectToIntermediatePage(slug, sku);
    }

    if (!isLoggedUser(user)) {
      redirectToLoginWithCartCallback();
    } else {
      window.location.href = window.DONATION_CART_KEY
        ? '/doacoes/carrinho'
        : '/carrinho';
    }
  }
};

export const addToSubscription = (
  source = '',
  selectedVariant = {},
  user,
  selectedPromotion
) => {
  customEventV2({
    event_category: 'product_page_btn',
    event_action: 'clickAddToSubscription'
  });

  if (testLoginAfterCartVariant) {
    redirectToCartWithoutLogin = true;
  }

  window.productSource = source;
  window.selectedVariant = selectedVariant;

  if (selectedVariant) {
    addSubscriptionToCartEvent(
      selectedVariant,
      'Product | Subscription Button'
    );
  }

  if (!selectedVariant || !selectedVariant.sku) {
    return;
  }

  if (!isLoggedUser(user) && !testLoginAfterCartVariant) {
    return redirectToLoginWithProductCallback(selectedVariant.slug);
  }

  if (isLoggedUser(user)) {
    pl_events.publish(pl_events.events.action_product_page_btn_loading, false);
    pl_events.publish(
      pl_events.events.repet.open_subscribe_product_modal,
      true
    );
    return;
  }

  const redirectToCartProps = {
    selectedVariant,
    selectedPromotion,
    user,
    subscribeOrder: { enabled: true }
  };

  redirectToCart(redirectToCartProps);
};

export const redirectToCart = ({
  selectedVariant,
  selectedPromotion = {},
  user,
  period = null,
  subscribeOrder = {},
  selectedVariantQuantity = 1,
  subscriptionBehavior = 'default'
}) => {
  submitSubscriptionEvent(selectedVariant, 'subscription_start');

  const product = {
    sku: selectedVariant?.combo_sku
      ? selectedVariant.combo_sku
      : selectedVariant.sku,
    period: period,
    quantity: selectedVariantQuantity,
    subscription_behavior: subscriptionBehavior
  };

  Cookie.set('PL_subscription_source', 'checkout_modal_product_page');

  addToCart(selectedVariant, selectedPromotion, user, subscribeOrder, product);
};

export const setCart = (order) => {
  const products = _setCartProducts(order);

  const cart = {
    totalItems: order.total_quantity,
    amount: order.total_with_credit,
    products
  };
  Cart.set(cart);
};

export const selectPromotion = (promotion, reset, variations) => {
  if (!promotion) {
    variations.selectedPromotion = {};
    variations.selectedVariant = variations.lastSelectedVariant;
    if (reset) {
      localStorage.removeItem('promotionSku');
    }
  } else {
    if (promotion.combo_sku) {
      localStorage.setItem('promotionSku', promotion.combo_sku);
    }
  }
  variations.selectedPromotion = promotion || {};
  variations.selectedVariant = Object.assign(
    {},
    variations.selectedVariant,
    promotion
  );
  return variations;
};

export async function calculateShipment(zipcode, sku, isLoading) {
  if (!zipcode || isLoading) return;
  document.activeElement.blur();
  const fingerprint = await FingerprintJS.load().then((fp) => fp.get());

  const orderNumber = Cookie.get(COOKIE_ORDER_NUMBER) || '';

  const orderToken = Cookie.get(COOKIE_ORDER_TOKEN) || '';

  return requestPost(
    `${getPetloveUrl()}/api/v4/dynamic_messages`,
    {
      order_number: orderNumber,
      dynamic_message: {
        skus: [{ sku: sku[0], quantity: 1 }],
        postal_code: zipcode,
        channel: 'Website',
        subchannel: getDevice()
      }
    },
    {
      Fingerprint: fingerprint.visitorId,
      'X-Spree-Order-Token': orderToken
    }
  );
}

export const parseRateClass = (reviews) => {
  const possibleStars = {
    icon_star: 'icon-star',
    icon_star_half: 'icon-star-half',
    icon_star_off: 'icon-star-off'
  };
  return reviews.map((review) => {
    review.display_created_at = formatDate(review.created_at);
    let avg = review.rating;
    review.rates = [];
    for (let i = 0; i < 5; i++) {
      if (avg > 0.75) {
        review.rates.push(possibleStars.icon_star);
      } else if (avg >= 0.25 && avg <= 0.75) {
        review.rates.push(possibleStars.icon_star_half);
      } else {
        review.rates.push(possibleStars.icon_star_off);
      }
      avg = avg - 1;
    }
    return review;
  });
};

const submitSubscriptionEvent = (selectedVariant = {}, eventName) => {
  mParticleSubscription(eventName, {
    ...selectedVariant,
    ...window.productJSON,
    source: 'product_page'
  });
};

export const parseStars = (ratings) => {
  let rating = parseFloat(ratings);
  let stars = [];

  for (let i = 0; i < 5; i++) {
    if (rating >= 0.75) {
      stars.push('IconStar');
    } else if (rating >= 0.25 && rating < 0.75) {
      stars.push('IconStarHalf');
    } else {
      stars.push('IconStarOutline');
    }
    rating = rating - 1;
  }

  return stars;
};

export const openRepetReplaceProductModal = () => {
  customEventV2({
    event_category: 'Subscription',
    event_action: 'replacement_flow',
    event_label: 'click_open_replace_product_modal'
  });
  pl_events.publish(pl_events.events.action_product_page_btn_loading, false);
  pl_events.publish(pl_events.events.repet.open_replace_product_modal, true);
};

export const repetReplaceProduct = (selectedVariant) => {
  customEventV2({
    event_category: 'Subscription',
    event_action: 'replacement_flow',
    event_label: 'click_replace_product_once'
  });

  const body = {
    consumable_id: selectedVariant.sku,
    quantity: 1,
    consumable_type: 'product',
    regularity_window: 'monthly'
  };

  replaceProduct(body, deliveryId, repetReplaceConsumable.id)
    .then(() => {
      pl_events.publish(
        pl_events.events.repet.open_replace_product_feedback_modal
      );
      pl_events.publish(
        pl_events.events.repet.open_replace_product_modal,
        false
      );
      Cookie.delete(COOKIE_REPET_REPLACE_PRODUCT_INIT);
      Cookie.delete(COOKIE_REPET_REPLACE_PRODUCT_CONSUMABLE);
    })
    .catch((e) => onRequestError(e));
};

export const repetRedirectToDelivery = () => {
  customEventV2({
    event_category: 'Subscription',
    event_action: 'replacement_flow',
    event_label: 'click_go_to_delivery'
  });

  window.location.href = `/repet/entregas/${deliveryId}`;
  Cookie.delete(COOKIE_REPET_REPLACE_PRODUCT_DELIVERY_ID);
};

export const handleSubscriptionBtnClick = (
  source,
  selectedVariant,
  user,
  selectedPromotion
) => {
  startedRepetReplaceProduct
    ? openRepetReplaceProductModal()
    : addToSubscription(source, selectedVariant, user, selectedPromotion);
};

export const openOneClickModal = (selectedVariant, user, selectedPromotion) => {
  const params = new URLSearchParams(window.location.search);
  const openModal = params.has('open_modal');

  if (openModal) {
    const productSlug = selectedVariant.slug;

    if (!isLoggedUser(user)) {
      redirectToLoginWithProductCallback(productSlug);
    }

    addToSubscription(
      'subscription_product',
      selectedVariant,
      user,
      selectedPromotion
    );
  }
};

function _setCartProducts(order = {}) {
  return order.line_items
    ?.filter((p) => !p.promotional_item && p.price > 0.2)
    .reduce(
      (acc, item, index) => {
        const shouldHavePipe = index !== 0 ? '|' : '';
        const brands =
          (!acc.brands.includes(item.brand) &&
            acc.brands + shouldHavePipe + item.brand) ||
          acc.brands;

        const categories =
          (!acc.categories.includes(item.category) &&
            acc.categories + shouldHavePipe + item.category) ||
          acc.categories;
        return {
          brands,
          categories
        };
      },
      {
        brands: '',
        categories: ''
      }
    );
}

export function isIntermediatePageEligible(subscribeOrder) {
  return (
    !window.DONATION_CART_KEY &&
    !cartCookie?.totalItems &&
    !window.webstore.isWhitelabel &&
    !subscribeOrder?.enabled
  );
}

export function redirectToIntermediatePage(slug, sku) {
  window.location.assign(`/${slug}/p/adicionar?sku=${sku}`);
}
