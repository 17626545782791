'use strict';

import { notifyHoneyBadgerError } from '@lib/honeyBadger';
import { getProductJSON, getProductPropertyName } from './productCommon';
import {
  mParticleViewDetail,
  mParticleAddToCart,
  mParticleRemoveFromCart,
  mParticlePurchase,
  mParticleSubscription,
  mParticleCustomEvent
} from './mparticle';

import { reportDynamicYieldEvent } from '@services/dynamicYieldService.js';

import { v4 as uuidv4 } from 'uuid';

window.dataLayer = window.dataLayer || [];

export const customEventV2 = ({
  event_category = '',
  event_action = '',
  event_label = '',
  event_noninteraction = 0,
  event_value = 0,
  value = 0.0
}) => {
  window.dataLayer = window.dataLayer || false;

  try {
    window.dataLayer.push({
      event: 'custom_event',
      event_category,
      event_action,
      event_label,
      event_noninteraction,
      event_value,
      value
    });
  } catch (error) {
    notifyHoneyBadgerError(error, {
      name: 'customEventV2 Error',
      event_category,
      event_action,
      event_label
    });
  }
};

//Verify if Neemu is Load
export function tagNeemuIsReady() {
  return typeof neemuPlugin != 'undefined';
}

window.tagNeemuIsReady = tagNeemuIsReady;

export const pageViewEvent = (event) => {
  window.dataLayer.push({ event: event });
};

export const initHome = () => {
  window.dataLayer.push({ event: 'HomeInfoReady' });
};

export const initProduct = () => {
  window.dataLayer.push({ event: 'ProductInfoReady' });
};

export const loginUserEvent = (action, label) => {
  customEventV2({
    event_category: 'User Login',
    event_action: action,
    event_label: label
  });
};

export const scrollPageView = () => {
  window.dataLayer.push({ event: 'scrollPage' });
};

export const subscription = (action) => {
  //Details
  //Adress
  //Delivery Date
  //Interval
  //Payment
  //Resume

  window.dataLayer.push({
    action: action,
    event: 'subscriptionEvent'
  });
};

export const initCatalog = (type, info) => {
  let event = '';

  switch (type) {
    case 'category':
      event = 'CategoryInfoReady';
      break;
    case 'brand':
      event = 'BrandInfoReady';
      break;
    case 'department':
      event = 'DepartmentInfoReady';
      break;
    default:
      event = 'SearchInfoReady';
  }

  if (event) {
    window.dataLayer.push({ info, event: event });
  }
};

export const viewCartOrder = (response, subscription) => {
  let order = response.data || response;
  if (!window.infoPetlove) {
    window.infoPetlove = {};
  }
  window.infoPetlove.order = order;
  let googleAnalytics = window.dataLayer;
  let items = order.line_items || [];
  let products = [];

  items.forEach(setProduct);

  let checkoutEvent = {
    checkout: {
      actionField: { step: 1 },
      products: products
    }
  };

  googleAnalytics.push({ event: 'CartInfoReady' });
  googleAnalytics.push({
    event: 'checkoutStep',
    checkoutType: getSubscriptionOptionLabel(subscription),
    ecommerce: checkoutEvent
  });
  googleAnalytics.push({ event: 'viewCheckoutEvent' });

  function setProduct(item) {
    let product = {
      name: item.full_name,
      id: item.product_sku,
      price: item.price,
      variant: item.short_name || item.full_name,
      quantity: item.quantity,
      metric4: item.subscriber_price,
      dimension28: item?.subscription_behavior === 'default',
      brand: item.brand,
      category: item.category,
      dimension29: 'nao-disponivel',
      dimension30: 'nao-disponivel',
      dimension31: 'nao-disponivel',
      dimension33: 'nao-disponivel',
      dimension34: item.subscription_behavior === 'place_order_now'
    };

    products.push(product);
  }
  return order;
};

export const getSubscriptionOptionLabel = (subscriptionOption = false) => {
  return String(subscriptionOption) == 'true'
    ? 'Com Passo de Assinatura'
    : 'Sem Passo de Assinatura';
};

export const checkoutStep = (
  step,
  subscriptionOption = false,
  products = [],
  order,
  isFakeEvent = false
) => {
  const event = isFakeEvent ? 'checkoutStepFake' : 'checkoutStep';
  const checkoutType = getSubscriptionOptionLabel(subscriptionOption);
  const isSubscription = order?.subscribed || order.subscription?.subscribed;

  const checkoutEventData = {
    event: event,
    dimension27: isSubscription,
    checkoutType,
    ecommerce: {
      checkout: {
        actionField: { step },
        products
      }
    }
  };

  window.dataLayer.push(checkoutEventData);
};

export const virtualPageView = (pagePath, pageType, isFakeEvent = false) => {
  const event = isFakeEvent ? 'virtualPageViewFake' : 'virtualPageView';
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event,
    pagePath,
    pageType
  });
};

export const checkoutStepOption = (step, option) => {
  window.dataLayer.push({
    event: 'checkoutStepOption',
    ecommerce: {
      checkout_option: {
        actionField: { step, option }
      }
    }
  });
};

export const submitPromotionEvent = (variant = {}) => {
  if (
    variant.promotional_item ||
    (variant.promotions && variant.promotions.length)
  ) {
    window.dataLayer.push({ event: 'ProductHasPromotion' });
  }
};

export const viewCheckoutOrderEvent = (order, user) => {
  window.infoPetlove.order = order;
  const items = order.line_items || [];
  const products = items.map((product) => {
    const {
      seller_info: { name }
    } = order.sellers_shipment_options.find(
      (option) =>
        option.seller_info.external_group_id === product.external_group_id
    );

    return setProduct(product, name);
  });
  const isFirstBuy = user.complete_orders === 1;
  const hasPetloveProducts = items.some(
    (product) => product.external_group_id === 0
  );
  const hasSellerProducts = items.some(
    (product) => product.external_group_id !== 0
  );

  let marketplace_type = 'Without Products';

  if (hasPetloveProducts && hasSellerProducts) {
    marketplace_type = '1P+3P';
  } else if (hasPetloveProducts) {
    marketplace_type = '1P';
  } else if (hasSellerProducts) {
    marketplace_type = '3P';
  }

  const checkoutEvent = {
    purchase: {
      order: {
        total: order.total_with_credit,
        shipping: order.ship_total,
        item_total: order.item_total
      },
      actionField: {
        id: order.number,
        affiliation: 'PetLove',
        revenue: order.total,
        tax: '',
        shipping: order.ship_total,
        coupon: order.promotion_codes[0] || '',
        isFirstBuy,
        isFirstSubscription:
          !user.subscriber_since && order.subscription.subscribed,
        completeOrders: user.complete_orders,
        discountClubOrderSubscribed:
          order?.discount_club_order_subscribed || false,
        dimension38: order.payment?.group || '',
        dimension40: order.subscription?.subscribed
          ? 'compra-recorrente'
          : order.subchannel === 'fechar_agora'
          ? 'fechar-agora'
          : 'compra-spot',
        business_unit: 'e-commerce',
        marketplace_type
      },
      products
    }
  };

  window.dataLayer.push({ event: 'purchase', ecommerce: checkoutEvent });
  window.dataLayer.push({ event: 'CompleteOrderInfoReady' });

  if (order?.discount_club_order_subscribed) {
    reportDynamicYieldEvent('Checkout Ecom | Order with Discount Club');

    let originOfClubPurchase;
    switch (order.discount_club?.origin) {
      case 'checkout':
        originOfClubPurchase = 'clube-checkout';
        break;
      case 'pdp':
        originOfClubPurchase = 'clube-pdp';
        break;
      case 'catalog':
        originOfClubPurchase = 'clube-catalogo';
        break;
      default:
        originOfClubPurchase = 'clube-carrinho';
        break;
    }

    const discountClubEvent = {
      purchase: {
        order: {
          total: order.discount_club?.plans[0]?.total_price,
          shipping: '',
          item_total: '1'
        },
        actionField: {
          id: uuidv4(),
          spot_purchase_id: order.number,
          revenue: order.discount_club?.plans[0]?.total_price,
          tax: '',
          shipping: '',
          discount: '',
          coupon: '',
          isFirstBuy,
          dimension38: 'credit_card',
          dimension40: originOfClubPurchase,
          business_unit: 'e-commerce'
        },
        products: [
          {
            id: 'discount_club_member_v3_3',
            category: 'Clube',
            name:
              order.discount_club?.title +
              ' - ' +
              order.discount_club?.plans[0]?.title,
            price: order.discount_club?.plans[0]?.total_price,
            quantity: '1'
          }
        ]
      }
    };

    window.dataLayer.push({ event: 'purchase', ecommerce: discountClubEvent });
  }

  if (!order.donation)
    _mParticleCheckoutOrderEvents(products, order, isFirstBuy, items);

  function setProduct(item, sellerName = 'Petlove') {
    return {
      name: item.name || item.short_name,
      id: item.sku,
      price: item.price,
      metric4: item.subscriber_price,
      variant: item.short_name,
      quantity: item.quantity,
      brand: item.brand,
      dimension28: item?.subscription_behavior === 'default',
      dimension34: item.subscription_behavior === 'place_order_now',
      item_marketplace_type: item?.external_group_id === 0 ? '1P' : '3P',
      item_vendedor_parceiro: sellerName
    };
  }
};

export const viewDetails = (variant = {}) => {
  const product = getProductJSON();
  const firstVariant = product.variants ? product.variants[0] : product;
  const products = [
    {
      name: variant.name,
      id: variant.sku,
      price: variant.price || firstVariant.price,
      brand: getProductPropertyName(product, 'brand'),
      category: product.category?.name,
      variant: variant.short_name || firstVariant.name,
      metric4: variant.discounted_price
    }
  ];

  window.dataLayer.push({
    event: 'productDetails',
    ecommerce: {
      detail: {
        products
      }
    }
  });

  submitPromotionEvent(variant);

  const mParticleProducts = _setMParticleProducts(products);
  mParticleViewDetail(mParticleProducts);
};

export const outOfStockEvent = (sku = '') => {
  customEventV2({
    event_category: 'Product Availability',
    event_action: 'Out of Stock',
    event_label: sku
  });
};

const getProduct = (item = {}, product = {}, variant = {}) => {
  const quantity = variant?.required_items?.[0]?.amount;

  return {
    name: item?.name || product?.name || variant?.fullName || variant?.name,
    id: item?.sku || variant?.sku || item?.id,
    price: item?.price || variant?.price,
    brand: variant?.brand || getProductPropertyName(product, 'brand'),
    category:
      variant?.category ||
      getProductPropertyName(product, 'category') ||
      product?.category,
    variant: item?.variant || variant?.short_name || variant?.originalShortName,
    metric4: variant?.discounted_price || variant?.subscriberPrice,
    quantity:
      item?.amount || quantity || variant?.quantity || product?.quantity || 1
  };
};

const getProductsArray = (variant = {}, product = {}) => {
  if (variant.length > 1) {
    return variant.map((item) => {
      return getProduct(item, product, variant);
    });
  } else {
    //for product combos, example: 20 sachês - 10% OFF
    if (variant?.required_items?.length > 1) {
      return variant.required_items.map((item) => {
        return getProduct(item, product, variant);
      });
    }
    return [getProduct(null, product, variant)];
  }
};

export const updateQuantityEvent = (
  event = 'addToCart',
  variant = {},
  type = 'Cart | Update Product Quantity'
) => {
  const product = setProductFromCart(variant);
  const data = {
    event: event
  };
  const products = getProductsArray(variant, product);
  const mParticleProducts = _setMParticleProducts(products);
  if (event === 'addToCart') {
    data.dimension39 = type;
    data.ecommerce = {
      currencyCode: 'BRL',
      add: {
        products: [product]
      }
    };
    mParticleAddToCart(mParticleProducts);
  } else {
    data.ecommerce = {
      remove: {
        products: [product]
      }
    };
    mParticleRemoveFromCart(mParticleProducts);
  }

  window.dataLayer.push(data);
};

export const setProductFromCart = (item = {}) => {
  const category =
    typeof item.categories === 'string'
      ? item.categories
      : item.categories?.join('/');

  const product = {
    name: item.full_name || item.name,
    id: item.product_sku || item.id || item.sku,
    price: item.price,
    metric4: item.subscriber_price,
    variant: item.short_name || item.full_name,
    quantity: item.quantity,
    dimension28: item?.subscription_behavior === 'default',
    brand: item.brand,
    category: category || item.category || 'nao-disponivel',
    dimension29: 'nao-disponivel',
    dimension30: 'nao-disponivel',
    dimension31: 'nao-disponivel',
    dimension33: 'nao-disponivel',
    dimension34: item.subscription_behavior === 'place_order_now'
  };

  if (item.list) {
    product.list = item.list;
    product.in_stock = item.in_stock;
    product.position = item.position;
    product.exclusive_brand = item.exclusive_brand;
  }
  return product;
};

export const addToCartEvent = (
  variant = {},
  event = '',
  list = '',
  type = false
) => {
  const product = getProductJSON();
  const products = getProductsArray(variant, product);

  const add = list ? { actionField: { list }, products } : { products };
  const eventName = event || 'addToCart';

  window.dataLayer.push({
    event: eventName,
    dimension39: type,
    ecommerce: {
      currencyCode: 'BRL',
      add
    }
  });

  const mParticleProducts = _setMParticleProducts(products);

  mParticleAddToCart(mParticleProducts);

  window.dataLayer.push({ event: 'addCartEvent' });
};

export const recommendationsImpression = (
  productsData = [],
  recommendationsList
) => {
  const products = productsData.map((product) => {
    let formattedProduct = formatDyProduct(product);
    formattedProduct.list = recommendationsList;
    return formattedProduct;
  });

  if (products.length > 10) {
    let n = parseInt(products.length / 2);

    const firstImpression = products.slice(0, n);
    const lastImpression = products.slice(n, products.length);
    window.dataLayer.push({
      event: 'productImpressions',
      ecommerce: {
        currencyCode: 'BRL',
        impressions: firstImpression
      }
    });
    window.dataLayer.push({
      event: 'productImpressions',
      ecommerce: {
        currencyCode: 'BRL',
        impressions: lastImpression
      }
    });
    return;
  }

  window.dataLayer.push({
    event: 'productImpressions',
    ecommerce: {
      currencyCode: 'BRL',
      impressions: products
    }
  });
};

export const productClick = (
  product,
  position = 0,
  shelf = 'Vitrine de recomendação'
) => {
  let actualPosition = position + 1;

  const productUpdatedForEvent = formatDyProduct(product);
  if (!productUpdatedForEvent.position) {
    productUpdatedForEvent.position = actualPosition;
  }

  window.dataLayer.push({
    event: 'ProductClick',
    ecommerce: {
      click: {
        actionField: {
          list: shelf
        },
        products: [productUpdatedForEvent]
      }
    }
  });
};

export const addToSubscriptionEvent = (productsData, isHitchhike = false) => {
  const products = productsData.map((product) => {
    return {
      id: product?.sku,
      name: product?.name,
      brand: product?.brand,
      variant: product?.short_name || product?.variants?.[0]?.short_name,
      price: product?.price,
      metric4: product?.subscriber_price,
      category: product?.categories?.[0],
      quantity: product?.quantity || 1,
      hitchhike: isHitchhike
    };
  });
  window.dataLayer.push({
    event: 'addToSubscription',
    ecommerce: {
      currencyCode: 'BRL',
      add: {
        products
      }
    }
  });
};

export const addSubscriptionToCartEvent = (
  variant = {},
  list = '',
  type = false
) => {
  const product = getProductJSON();
  const products = getProductsArray(variant, product);
  const add = list ? { actionField: list, products } : { products };

  window.dataLayer.push({
    event: 'addSubscriptionToCart',
    dimension39: type,
    ecommerce: {
      currencyCode: 'BRL',
      add
    }
  });
};

export const clickProductEvent = (product) => {
  const { id, name, brand, variant, price, metric4, position, list } = product;
  const products = [
    { id, name, brand, variant, price, metric4, position, list }
  ];

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'productShelfClick',
    ecommerce: {
      click: {
        actionField: { list },
        products
      }
    }
  });
};

export const catalogClickEvent = (product, category, queryId) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'catalogClick',
    queryId: queryId,
    ecommerce: {
      click: {
        actionField: { list: 'catalogListName' },
        products: [
          {
            name: product.nome,
            id: product.sku,
            price: product.de,
            metric4: product.por,
            brand: product.marca,
            category,
            dimension29: product?.extras_id_categoria?.split('/'),
            dimension30: false,
            dimension31: false,
            dimension32: product.disponivel !== 1,
            dimension33: false,
            variant: product.extras?.skus?.[0]?.properties?.spec,
            position: 1
          }
        ]
      }
    }
  });
};

const _formatCatalogProducts = (products, position, shelf, showList) => {
  let productList = products.map((product) => {
    return {
      name: product.nome,
      id: product.sku,
      price: product.de,
      metric4: product.preco_assinante,
      brand: product.marca,
      category: product.extras_id_categoria?.split('/')[0],
      dimension29: 'nao-disponivel',
      dimension30: 'nao-disponivel',
      dimension31: 'nao-disponivel',
      dimension32: product.disponivel !== 1,
      variant: product.sku_spec,
      position: position ? position : product.position
    };
  });

  if (showList) {
    productList.forEach((product) => {
      product.list = shelf;
    });
  }

  return productList;
};

export const catalogImpressionsEvent = (impressions) => {
  if (!impressions || impressions.length < 1) {
    return;
  }
  const formattedImpressions = _formatCatalogProducts(
    impressions,
    false,
    'catalogo',
    true
  );

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'catalogImpressions',
    ecommerce: {
      currencyCode: 'BRL',
      impressions: formattedImpressions
    }
  });
};

export const subscriptionLandingSimulate = (label) => {
  customEventV2({
    event_category: 'Subscription',
    event_action: 'simulate-economy',
    event_label: label
  });
};

export const clickButtonWhatsapp = (target) => {
  customEventV2({
    event_category: 'Contact by Whatsapp',
    event_action: target.textContent,
    event_label: window.webstore.title
  });
};

export const setProductsToGA = (order = {}) => {
  const products = order.line_items;
  const isOrderSubscribed = order?.subscribed || order.subscription?.subscribed;

  if (!products) return [];

  return products.map((item) => ({
    name: item.name || item.short_name,
    id: item.sku,
    category: item.category,
    price: item.price,
    metric4: item.subscriber_price,
    variant: item.short_name,
    quantity: item.quantity,
    brand: item.brand,
    dimension27: isOrderSubscribed,
    dimension28: item?.subscription_behavior === 'default',
    dimension29: item?.category,
    dimension34: item.subscription_behavior === 'place_order_now'
  }));
};

/**
 * TEST
 * this event will be used to track an unusual behavior of whitelabel stores
 */
(function trackWhitelabelStore() {
  if (!window?.webstore?.isWhitelabel) {
    return;
  }

  const { subdomain: whitelabelSubdomain } = window.webstore;
  const [hostnameSubdomain] = window.location.hostname.split('.');

  whitelabelSubdomain !== hostnameSubdomain &&
    customEventV2({
      event_category: 'Error loading store',
      event_action: `store loaded: ${whitelabelSubdomain}`
    });
})();

function _setMParticleProducts(products) {
  return products.map((product) => ({
    ...product,
    sku: product.id
  }));
}

function _mParticleCheckoutOrderEvents(products, order, isFirstBuy, items) {
  const mParticleProducts = _setMParticleProducts(products);
  mParticlePurchase(mParticleProducts, order, isFirstBuy);
  _sendIncrementalityEvent(items, order);

  if (order?.subscription?.subscribed) {
    mParticleProducts.forEach((product) =>
      mParticleSubscription('new_subscription', {
        ...product,
        source: 'checkout',
        new_subscriber: isFirstBuy
      })
    );
  }
}

function _sendIncrementalityEvent(products, order) {
  if (products.length <= 1) return;

  let categories = [];
  const incrementalityEventPayload = products
    .filter((p) => !p.promotional_item && p.price > 0.2)
    .reduce(
      (acc, item, index) => {
        const shouldHavePipe = index !== 0 ? '|' : '';
        if (!categories.includes(item.category)) categories.push(item.category);

        return {
          items_brand: acc.items_brand + shouldHavePipe + item.brand,
          items_category: acc.items_category + shouldHavePipe + item.category,
          items_id: acc.items_id + shouldHavePipe + item.sku,
          items_name: acc.items_name + shouldHavePipe + item.name,
          quantity: acc.quantity + shouldHavePipe + item.quantity
        };
      },
      {
        items_brand: '',
        items_category: '',
        items_id: '',
        items_name: '',
        quantity: ''
      }
    );

  if (categories.length > 1) {
    mParticleCustomEvent(
      'incrementality',
      'Transaction',
      incrementalityEventPayload
    );

    customEventV2({
      event_category: 'Incrementality',
      event_action: 'Cross-sell',
      event_label: incrementalityEventPayload.items_category,
      event_noninteraction: 0,
      event_value: order.total
    });
  }
}

export const productClickCatalog = (product, position, shelf = 'catalogo') => {
  position += 1;

  const products = [product];

  const formattedProducts = _formatCatalogProducts(
    products,
    position,
    shelf,
    false
  );

  window.dataLayer.push({
    event: 'ProductClick',
    ecommerce: {
      click: {
        actionField: {
          list: shelf
        },
        products: formattedProducts
      }
    }
  });
};

export const userDataEvent = (order, user) => {
  const { city, state, zipcode } = order.address;
  const { name, lastname, phone, email, public_id } = user;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'userData',
    public_id,
    first_name: name,
    last_name: lastname,
    phone,
    email,
    country: 'br',
    zip_code: zipcode,
    state,
    city
  });
};

function formatDyProduct(product) {
  if (!product?.sku) return {};
  const category =
    typeof product.categories === 'string'
      ? product.categories
      : product.categories?.join('/');

  return {
    id: product?.sku,
    name: product?.name,
    brand: product?.brand,
    variant: product?.short_name,
    price: product?.price,
    metric4: Number(product?.subscriber_price),
    category,
    exclusive_brand: product?.exclusive_brand === 'true' ? true : false,
    dimension29: 'nao-disponivel',
    dimension30: 'nao-disponivel',
    dimension31: 'nao-disponivel',
    out_of_stock: !product?.in_stock,
    dimension33: 'nao-disponivel',
    position: product?.position
  };
}
