'use strict';

import { requestGet, requestPost } from '@lib/fetch';
import { Cookie } from '@lib/cookies';
import isSubscriber from '@app/assets/scripts/v2/lib/isSubscriber';
import { formatPrice } from '@lib/priceUtils';
import { notifyHoneyBadgerError } from '@lib/honeyBadger';

const DY_USER_ID_COOKIE = '_dyid';
const DY_SESSION_COOKIE = '_dyjsession';
const DY_ID_SERVER_COOKIE = '_dyserver';

const DY_CHOOSE_API_URL = 'https://direct.dy-api.com/v2';
const DY_COLLECT_API_URL = 'https://direct-collect.dy-api.com/v2';

export const getDYChoose = ({
  experiments,
  sku,
  pageType,
  customAttributes,
  args
}) => {
  if (!window.mParticle.ready) return Promise.reject('mParticle is not ready');

  const payload = {
    experiments,
    sku,
    page_type: pageType,
    args,
    page_attributes: {
      pet_species: Cookie.get('PL_pet_species') || '',
      subscriber: isSubscriber() || false,
      whitelabel_segment: window?.webstore?.segment?.id || 0,
      ...customAttributes
    }
  };
  const endpoint = `/service/dynamic_yield/choose`;
  return requestPost(endpoint, payload);
};

export const reportDynamicYieldClick = (id, engagement_type) => {
  return requestGet(
    `/service/dynamic-yield/report-dy-click?id=${id}&engagement_type=${engagement_type}`
  );
};

export const reportDynamicYieldEvent = (event_name) => {
  return requestGet(
    `/service/dynamic-yield/report-dy-event?event_name=${event_name}`
  );
};

export function formatRecommendations(products = []) {
  return products.map((variation, index) => {
    const product = variation.productData;
    const hasBestDiscount = parseFloat(product.discount_percentage || 0) >= 10;
    const subscriberPrice = hasBestDiscount
      ? product.dy_display_price
      : product.subscriber_price;

    return {
      ...product,
      sku: variation.sku,
      id: variation.sku?.split('-')?.[0],
      position: index + 1,
      slotId: variation.slotId,
      displayPrice: formatPrice(parseFloat(product.list_price)),
      subscriberPrice: formatPrice(parseFloat(subscriberPrice))
    };
  });
}

export async function getRecommendations(
  apiKey,
  experiment,
  cart_line_items,
  args = {}
) {
  const line_items = cart_line_items || [];
  const items = line_items.filter((item) => {
    return item !== item.promotional_item;
  });

  const data = {
    args,
    experiment,
    pageType: 'CART',
    sku: items ? items.map((variant) => variant.sku) : ''
  };

  return await getDirectDYChoose(apiKey, data)
    .then((res) => {
      const choices = res?.choices || [];
      const campaigns = choices.map((choice) => {
        const campaigns = choice.variations;
        const data = campaigns?.[0]?.payload?.data;
        const type = data?.custom?.type;
        const title = data?.custom?.title;
        return {
          title,
          type,
          products: data?.slots
        };
      });
      return campaigns;
    })
    .catch((err) => {
      console.error(
        'Dynamic Yield experiment failed to load - ',
        err?.statusText
      );
    });
}

export function realtimeQuery(experiment, type = 'include', conditions = []) {
  if (!experiment || !conditions?.length) return;
  if (type !== 'include' && type !== 'exclude') return;

  return {
    [experiment]: {
      realtimeRules: [
        {
          type,
          slots: [],
          query: {
            conditions
          }
        }
      ]
    }
  };
}

const _userData = () => ({
  user: {
    dyid: Cookie.get(DY_USER_ID_COOKIE),
    dyid_server: Cookie.get(DY_ID_SERVER_COOKIE)
  },
  session: { dy: Cookie.get(DY_SESSION_COOKIE) }
});

const _setDyCookies = (cookies) => {
  if (!Array.isArray(cookies)) return;

  const [dyServer, dySession] = cookies;
  dyServer?.value && Cookie.set(DY_USER_ID_COOKIE, dyServer.value, 365);
  dySession?.value && Cookie.set(DY_SESSION_COOKIE, dySession.value, 0, 30);
};

const setHeaders = (apiKey) => {
  return {
    accept: 'application/json',
    'content-type': 'application/json',
    'DY-API-Key': apiKey || window.DY_CLIENT_KEY || ''
  };
};

export const getDirectDYChoose = async (
  apiKey,
  { sku, args, pageType, experiment, customAttributes = {} }
) => {
  if (!apiKey) throw new Error('Dynamic Yield API Key is required');
  if (!experiment) throw new Error('Experiment is required');
  if (!pageType) throw new Error('PageType is required');

  const campaign = Array.isArray(experiment) ? experiment : [experiment];

  const body = {
    ..._userData(),
    context: {
      page: {
        type: pageType,
        data: sku ? (Array.isArray(sku) ? sku : [sku]) : [],
        location: window.location.href,
        referrer: document.referrer || ''
      },
      channel: 'web',
      pageAttributes: {
        ...getPageAttributes(),
        ...customAttributes
      }
    },
    selector: { names: campaign, ...args },
    options: {
      isImplicitPageview: false,
      isImplicitImpressionMode: true,
      isImplicitClientData: true,
      returnAnalyticsMetadata: true
    }
  };

  const response = await fetch(`${DY_CHOOSE_API_URL}/serve/user/choose`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: setHeaders(apiKey)
  });

  let data = {};

  try {
    data = await response.json();
  } catch (error) {
    notifyHoneyBadgerError(error, 'Error on Direct DY Choose');
  }

  if (Cookie.get(DY_USER_ID_COOKIE) && data?.cookies) {
    _setDyCookies(data.cookies);
  }

  return data;
};

export const reportSlotEngagement = (id) => {
  try {
    if (!Cookie.get(DY_USER_ID_COOKIE) || !Cookie.get(DY_SESSION_COOKIE))
      return;
    const engagement = { slotId: id, type: 'SLOT_CLICK' };
    return fetch(`${DY_COLLECT_API_URL}/collect/user/engagement`, {
      method: 'POST',
      body: JSON.stringify({
        ..._userData(),
        engagements: [engagement]
      }),
      headers: setHeaders()
    });
  } catch (error) {
    console.error(`Error sending slot engagement to DY:`, error);
  }
};

const getPageAttributes = () => {
  const isSubscriber =
    !!Cookie.get('PL_isSubscriber') || !!window.infoPetlove?.subscriber;

  const isDiscountClubMember =
    !!Cookie.get('PL_isDiscountClubMember') ||
    !!window.infoPetlove?.discount_club_member;

  const cartData = JSON.parse(Cookie.get('PL_cart') || '{}');
  const { brands, categories } = cartData?.products || {};

  return {
    pet_species: Cookie.get('PL_pet_species') || '',
    subscriber: isSubscriber,
    discountclub_member: isDiscountClubMember,
    cart_brands: brands || '',
    cart_categories: categories || ''
  };
};
