'use strict';

import { requestPut, requestGet } from '@lib/fetch';
import {
  Cookie,
  COOKIE_ZIPCODE,
  COOKIE_PENDING_COUPON,
  COOKIE_ORDER_NUMBER,
  COOKIE_EMAIL,
  COOKIE_ORDER_TOKEN,
  Promotion
} from '@lib/cookies';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const CART_INFO = {
  email: '',
  line_items: [],
  coupon: '',
  subscription: {
    period: 0
  },
  utm: {
    source: '',
    campaign: '',
    medium: ''
  },
  postal_code: '',
  number: '',
  token: ''
};

const LOCAL_STORAGE_CART_KEY = window.DONATION_CART_KEY || 'PL_local_variants';
const LOCAL_STORAGE_LAST_VARIANT_KEY =
  window.DONATION_LAST_VARIANT_KEY || 'PL_local_last_variant_updated';

export function setLocalOrder(number = '', token = '') {
  Cookie.set(COOKIE_ORDER_NUMBER, number);
  Cookie.set(COOKIE_ORDER_TOKEN, token);
}

export async function upsertCart(cartInfo, data) {
  const { email, number, token } = cartInfo;
  let postalCode = Cookie.get('PL_zipcode');
  const url = `/service/cart/upsert`;
  const headers = {};
  const fingerprint = await FingerprintJS.load().then((fp) => fp.get());

  if (email && data.order) {
    data.order.email = email;
  }

  if (number) {
    data.number = number;
  }

  if (postalCode) {
    postalCode = postalCode.replace(/[-]/g, '');
    data = { ...data, postal_code: postalCode };
  }

  if (token) {
    headers.token = token;
  }

  headers.fingerprint = fingerprint.visitorId;

  return requestPut(url, data, headers).then((res) => {
    const { number, token } = res.order;
    setLocalOrder(number, token);
    return res;
  });
}

export const setOrderSubscription = (products, subscribed, period, source) => {
  const zipcode = Cookie.get('PL_zipcode');

  let data = {
    zipcode,
    products: products || [],
    subscribed,
    period,
    source
  };
  return requestPut('/carrinho/subscription', data);
};

export const setOrderDiscountClub = (option) => {
  const value = option === 'true';
  let data = {
    order: {
      discount_club: {
        discount_club_subscribed: Boolean(value)
      }
    }
  };
  return requestPut('/carrinho/clube-de-descontos', data);
};

export const addVariantToSubscription = (source = '', variant = {}) => {
  if (!variant.sku) return;
  if (variant.slug) {
    Cookie.set('PL_product_slug', variant.slug);
  }

  window.location.assign(
    `/carrinho/add/variant_sku/${variant.sku}/quantity/1?subscribed_order=true&source=${source}`
  );
};

export async function addVariantToCart(
  variants = [],
  subscription,
  orderInfos = {}
) {
  const { utms, ...cartInfo } = getLocalInfo();

  const localVariants = getLocalStorageVariants();

  let data = {
    order: {
      line_items_attributes: [...variants, ...localVariants],
      ...orderInfos,
      ...utms
    }
  };

  if (subscription) {
    data.subscription = subscription;
  }

  clearLocalStorageVariants();

  return upsertCart(cartInfo, data);
}

export function getCookieOrder() {
  return {
    number: Cookie.get(COOKIE_ORDER_NUMBER),
    token: Cookie.get(COOKIE_ORDER_TOKEN)
  };
}

export function getCookieZipcode() {
  return Cookie.get(COOKIE_ZIPCODE) || '';
}

export function getLocalStorageVariants() {
  const variants = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_CART_KEY) || '[]'
  );

  return variants.map((variant) => {
    return {
      id: variant.id,
      sku: variant.sku,
      quantity: variant.quantity
    };
  });
}

export function clearLocalStorageVariants() {
  window.localStorage.removeItem(LOCAL_STORAGE_CART_KEY);
  window.localStorage.removeItem(LOCAL_STORAGE_LAST_VARIANT_KEY);
}

export function getLocalInfo() {
  return {
    email: Cookie.get(COOKIE_EMAIL) || '',
    coupon: Cookie.get(COOKIE_PENDING_COUPON) || '',
    postal_code: Cookie.get(COOKIE_ZIPCODE) || '',
    number: Cookie.get(COOKIE_ORDER_NUMBER) || '',
    token: Cookie.get(COOKIE_ORDER_TOKEN) || '',
    utms: Promotion.getUtmCookies() || {}
  };
}

export function getOrderDetails(
  userToken,
  shipping_method_id,
  order = {},
  zipcode = null
) {
  let { postal_code, number, token } = getLocalInfo();

  if (!number) {
    number = order.number;
    token = order.token;
  }

  if (zipcode) {
    postal_code = zipcode;
  }

  const params = {
    number,
    shipping_method_id,
    ...(order.completed_at
      ? { postal_code: order.address.displayZipcode }
      : postal_code
      ? { postal_code }
      : {})
  };

  const queryString = new URLSearchParams(params).toString();

  const url = `/service/cart/${number}/details?${queryString}`;

  const headers = {
    'X-Spree-Token': userToken,
    'X-Spree-Order-Token': token
  };

  return requestGet(url, headers).then((res) => {
    return res;
  });
}

export function cancelRepetAdvancedDeliveries(orderNumber, orderToken) {
  const data = {
    order: {
      number: orderNumber,
      token: orderToken
    }
  };
  const url = `/service/cart/cancel_repet_advanced_deliveries`;
  return requestPut(url, data);
}

export function updateRepetAdvancedOrder(order) {
  const data = {
    order
  };

  const url = `/service/cart/update_repet_advanced_order`;
  return requestPut(url, data);
}

export function getProductsPurchased() {
  return requestGet('/service/cart/products_purchased');
}
