'use strict';

export function currency(num) {
  let result = [];
  num = Number(num).toFixed(2).split('.');
  let m = num[0];

  for (
    let s = m.length % 3, i = s ? 0 : 1, iLen = (m.length / 3) | 0;
    i <= iLen;
    i++
  ) {
    result.push(m.substr(i ? (i - 1) * 3 + s : 0, i ? 3 : s));
  }
  return 'R$' + result.join('.') + ',' + num[1];
}

export default {
  currency
};
