import { getAllCookiesByName } from '@lib/cookies';

/*
Get  Running Tests from Cookies and Return Object
Example:
Cookie Value:
PL_ab_test_subscription: 'variant_a'
Return:
{
  ab_test_subscription: 'variant_a'
}
*/

export function getRunningAbTests(cookieName = '') {
  const cookieExperiments = getAllCookiesByName(cookieName);
  let activeExperiments = {};
  if (!cookieExperiments.length) return activeExperiments;

  for (let experiment of cookieExperiments) {
    const [cookieAbName, abTestValue] = experiment.split('=');
    const [, abTestName] = cookieAbName.split('PL_');

    activeExperiments[abTestName] = abTestValue;
  }

  return activeExperiments;
}
