'use strict';

const events = {
  social_login: 'social-login',
  zipcode_header: 'zipcode-header',
  ab_progress_bar_recommendation: 'ab-progress-bar-recommendation',
  ab_progress_bar_mobile: 'ab-progress-bar-mobile',
  ab_progress_bar_desktop: 'ab-progress-bar-desktop',
  ab_marketplace_quotation_desktop: 'ab_marketplace_quotation_desktop',
  prime_header_label: 'prime-header-label',
  header_zipcode: 'header-zipcode',
  prime_badge_event: 'prime-badge-event',
  cart_order_ready: 'cart-order-ready',
  cart_ab_economy: 'cart-ab-economy',
  cart_subscribe: 'cart-subscribe',
  cart_shipment_calculate: 'cart-shipment-calculate',
  cart_subsription_period_changed: 'cart-subscription-period-changed',
  delivery_subscribe: 'delivery-subscribe',
  onload_start_categories: 'onload-start-categories',
  onload_start_pets_recommendations: 'onload-start-pets-recommendations',
  onload_start_pets_list: 'onload-start-pets-list',
  user_pets_loaded: 'user_pets_loaded',
  user_loaded: 'user-loaded',
  user_public: 'user-public',
  hide_subscription_banner: 'user-subscription',
  start_recommendations: 'start-recommendations',
  main_element_toogle_class: 'main-element-toogle-class',
  alert_message: 'alert-message',
  update_order: 'update-order',
  shipment_changed: 'shipment-changed',
  order_ready: 'order_ready',
  update_shipment: 'update_shipment',
  start_recommendations_in_cart: 'start-recommendations-in-cart',
  add_recomendation_product: 'add-recomendation-product',
  cart: {
    update_order_error: 'update-order-error',
    update_coupon: 'update-coupon'
  },
  product_loading: 'product-loading',
  action_product_page_btn_loading: 'action-product-page-btn-loading',
  body_app_locked: 'body-app-locked',
  hamburger_is_active: 'hamburger-is-active',
  update_selected_variant: 'update-selected-variant',
  open_gallery: 'open-gallery',
  ab_test_not_found: 'ab-test-not-found',
  ab_delivery_message: 'ab-delivery-message',
  close_shipment_modal: 'close-shipment-modal',
  close_subscription_modal: 'close-subscription-modal',
  open_select_variants: 'open-select-variants',
  open_product_modal_idenfify: 'open-product-modal-identify',
  product_remindme: 'product-remindme',
  flash_message: 'flash-message',
  open_member_get_member_modal: 'open-member-get-member-modal',
  new_coupon: 'new-coupon',
  stop_loading: 'stop-loading',
  init_test_ab: 'init-test-ab',
  zipcode_changed: 'zipcode-changed',
  repet: {
    open_subscribe_product_modal: 'open-subscribe-product-modal',
    open_subscribe_product_confirmation_modal:
      'open-subscribe-product-confirmation-modal',
    next_delivery_date: 'next-delivery-date',
    open_replace_product_modal: 'open-replace-product-modal',
    open_replace_product_feedback_modal: 'open-replace-product-feedback-modal',
    get_recurrences: 'get-recurrences',
    open_how_it_works_modal: 'open-how-it-works-modal',
    hide_add_products_alert: 'hide-add-products-alert',
    test_add_products_page: 'test-add-products-page',
    open_frequency_recommendation_modal: 'open-frequency-recommendation-modal'
  },
  product_page_view_test: 'product-page-view-test',
  show_app_modal_test: 'show_app_modal_test',
  test_login_after_cart: 'test-login-after-cart',
  test_modal_product_cards_catalog: 'test-modal-product-cards-catalog',
  test_catalog_product_cards_modal_v2: 'test-catalog-product-cards-modal-v2',
  test_discountclub_checkout: 'test-discountclub-checkout',
  automatic_login_sso: 'automatic-login-sso',
  add_discount_club_variant: 'add-discount-club-variant'
};

let topics = window.topics || {};
let published = window.published || {};
let hOP = topics.hasOwnProperty;

window.pl_events = window.pl_events || {
  topics,
  published,

  /**
   *
   * @param {String} topic topic to subscribe
   * @param {Function} listener function to be called in publish
   */
  subscribe(topic, listener) {
    // Create the topic's object if not yet created
    if (!hOP.call(this.topics, topic)) this.topics[topic] = [];

    // Add the listener to queue
    let index = this.topics[topic].push(listener) - 1;

    // Provide handle back for removal of topic
    return {
      remove() {
        delete this.topics[topic][index];
      }
    };
  },
  /**
   *
   * @param {String} topic name of the topic to be published
   * @param {String|Object|Number|Boolean} info info to publish for the subscriber
   * @param {Boolean} saveHistory save published info to lazy components access it
   */
  publish(topic, info, saveHistory) {
    info = info != undefined ? info : {};
    // If the topic doesn't exist, or there's no listeners in queue, just leave
    if (!hOP.call(this.topics, topic)) return;

    // Cycle through this.topics queue, fire!
    this.topics[topic].forEach((fn) => {
      fn(info);
      if (saveHistory) {
        this.published[topic] = info;
      }
    });
  },
  getPublishedEvent(topic) {
    return this.published[topic];
  },
  existPublishedEvent(topic) {
    return this.published[topic] ? true : false;
  },
  events
};

let pl_events = window.pl_events; //TODO: improve this

export default pl_events;
