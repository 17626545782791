'use strict';

//https://docs.honeybadger.io/lib/javascript/integration/browser.html

export function notifyHoneyBadgerError(error, context, fingerprint) {
  if (!window.Honeybadger || !error) return;
  if (context) {
    window.Honeybadger.setContext(context);
  }
  const options = {};
  if (fingerprint) options.fingerprint = fingerprint;

  window.Honeybadger.notify(error, options);
}

export function initializeVueErrorHandling(Vue) {
  Vue.config.warnHandler = (msg, _, trace) => {
    notifyHoneyBadgerError(new Error(`[Vue warn]: ${msg}\nTrace: ${trace}`));
  };

  Vue.config.errorHandler = (err, _, info) => {
    notifyHoneyBadgerError(err, { info });
  };
}
