import currencyModule from './filterCurrency';

const LOCAL_STORAGE_CART_KEY = window.DONATION_CART_KEY || 'PL_local_variants';
const LOCAL_STORAGE_LAST_VARIANT_KEY =
  window.DONATION_LAST_VARIANT_KEY || 'PL_local_last_variant_updated';

const LOCAL_STORAGE_CART_SERVICE = {
  lineItem(variant, isOrderItem) {
    variant.images = variant.images || [];
    variant.images[0] = variant.images[0] || { product_url: '' };
    let item = {
      image:
        variant.image ||
        variant.images.default ||
        variant.images[0].product_url,
      full_name: variant.full_name || variant.name,
      name: variant.name,
      quantity: variant.quantity || 1,
      display_price:
        variant.display_price || currencyModule.currency(variant.price),
      display_list_price:
        variant.display_list_price ||
        currencyModule.currency(variant.list_price),
      list_price: variant.list_price,
      id: isOrderItem ? variant.id : null,
      sku: variant.sku,
      slug: variant.slug || variant.path,
      promotional_item: variant.promotional_item,
      has_discount: variant.has_discount,
      hitchhike: variant.hitchhike,
      display_discount_percentage: variant.display_discount_percentage,
      display_increase_total: variant.display_increase_total,
      increase_total: variant.increase_total,
      unavailable: variant.unavailable || false
    };
    return item;
  },
  syncLineItems(lineItems) {
    let newLocalVariants = lineItems.map((lineItem) =>
      this.lineItem(lineItem, true)
    );
    return this.set(newLocalVariants);
  },
  get() {
    let variants = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_CART_KEY) || '[]'
    );
    return variants;
  },
  set(variants) {
    localStorage.setItem(LOCAL_STORAGE_CART_KEY, JSON.stringify(variants));
    return this.get();
  },
  add(variant, comboQuantity) {
    let variants = this.get(LOCAL_STORAGE_CART_KEY);

    this.setLastUpdated(variant);

    if (variants.length == 0) {
      variants.push(this.lineItem(variant));
    } else {
      let localVariantNotExist = this.localVariantExist(variants, variant);
      if (localVariantNotExist) {
        variants.push(this.lineItem(variant));
      } else {
        variants = variants.map((localVariant) => {
          if (this.isSameVariant(variant, localVariant)) {
            localVariant.quantity += parseInt(comboQuantity, 10) || 1;
          }
          return localVariant;
        });
      }
    }
    return this.set(variants);
  },
  remove(variant, force, inSync, orderNumber) {
    this.setLastUpdated(variant);
    let variants = this.get(LOCAL_STORAGE_CART_KEY);
    variants = variants.filter((localVariant) => {
      if (this.isSameVariant(variant, localVariant)) {
        if (localVariant.quantity === 1 || force) {
          if (inSync && !orderNumber) {
            return false; //remove from array to fix loop while syncing cart
          } else if (localVariant.id) {
            localVariant._destroy = true;
            return true;
          } else {
            return false;
          }
        } else {
          localVariant.quantity -= 1;
          return true;
        }
      } else {
        return true;
      }
    });
    return this.set(variants);
  },
  update(newVariant, quantity) {
    this.setLastUpdated(newVariant);
    let variants = this.get(LOCAL_STORAGE_CART_KEY);
    variants = variants.map((localVariant) => {
      if (this.isSameVariant(newVariant, localVariant)) {
        localVariant.quantity = quantity;
      }
      return localVariant;
    });
    return this.set(variants);
  },
  findVariant(sku) {
    let localVariants = this.get();
    return localVariants.reduce((variant, localVariant) => {
      if (sku == localVariant.sku) {
        variant = localVariant;
      }
      return variant;
    }, {});
  },
  localVariantExist(variants, variant) {
    return (
      variants.filter((localVariant) =>
        this.isSameVariant(variant, localVariant)
      ).length === 0
    );
  },
  isSameVariant(variant, localVariant) {
    return variant.id == localVariant.id; //comparing id in product page
  },
  count() {
    let variants = this.get();
    return variants.reduce((total, variant) => {
      total += variant.quantity;
      return total;
    }, 0);
  },
  setLastUpdated(variant) {
    localStorage.setItem(LOCAL_STORAGE_LAST_VARIANT_KEY, variant.sku);
  },
  removeLineItemsWithProblems(skus, inSync, orderNumber) {
    skus.forEach((sku) => {
      let variant = this.findVariant(sku);
      this.removeOrUpdate(variant, inSync, orderNumber);
    });
    return this.get();
  },
  removeOrUpdate(variant, inSync, orderNumber) {
    if (variant.quantity == 1) {
      this.remove(variant, true, inSync, orderNumber);
    } else {
      this.update(variant, 1);
    }
  },
  removeLastUpdated() {
    let lastVariantSku = localStorage.getItem(LOCAL_STORAGE_LAST_VARIANT_KEY);
    let variant = this.findVariant(lastVariantSku);
    return this.removeOrUpdate(variant);
  },
  reset() {
    localStorage.setItem(LOCAL_STORAGE_CART_KEY, JSON.stringify([]));
    localStorage.setItem(LOCAL_STORAGE_LAST_VARIANT_KEY, '');
  }
};

export default LOCAL_STORAGE_CART_SERVICE;
