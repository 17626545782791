'use strict';

export function removeHash() {
  let scrollV,
    scrollH,
    loc = window.location;
  if ('pushState' in history)
    history.pushState('', document.title, loc.pathname + loc.search);
  else {
    // Prevent scrolling by storing the page's current scroll offset
    scrollV = document.body.scrollTop;
    scrollH = document.body.scrollLeft;

    loc.hash = '';

    // Restore the scroll offset, should be flicker free
    document.body.scrollTop = scrollV;
    document.body.scrollLeft = scrollH;
  }
}

export const raf =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  window.setTimeout;

export const getPetloveUrl = () => {
  return `${process.env.WEBSTORE_PROTOCOL}://${process.env.WEBSTORE_API_HOSTNAME}:${process.env.WEBSTORE_PORT}`;
};

export const getFocaUrl = () => {
  return `${process.env.FOCA_PROTOCOL}://${process.env.FOCA_HOSTNAME}:${process.env.FOCA_PORT}`;
};

export const getDuckUrl = () => {
  return `${process.env.DUCK_PROTOCOL}://${process.env.DUCK_HOSTNAME}:${process.env.DUCK_PORT}`;
};

export const getLabradorUrl = () => {
  return `${process.env.LABRADOR_URL}`;
};

export const getWhaleUrl = () => {
  return `${process.env.WHALE_PROTOCOL}://${process.env.WHALE_CLIENT}`;
};

export const getAlgoliaUrl = () => {
  return `${process.env.ALGOLIA_URL}`;
};

export const getPrescriptionUrl = () => {
  return `${process.env.PRESCRIPTION_PROTOCOL}://${process.env.PRESCRIPTION_HOSTNAME}`;
};

export const getDevice = () => {
  return window.isMobileAccess ? 'mobile' : 'desktop';
};

export const isPetloveProduction = () => {
  const petloveUrl = 'www.petlove.com.br';
  return window.location.hostname === petloveUrl;
};

export const initGlobal = () => {
  window.infoPetlove = {
    client: {},
    order: {},
    product: window.productJSON || {}
  };
  window.BUILD_NUMBER = process.env.BUILD_NUMBER;
};
